<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <logo-text />
        </b-link>

        <b-card-title class="mb-1"> Wachtwoord vergeten? 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Geef je e-mailadres in en we sturen je instructies om je wachtwoord te
          herstellen.
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="restore"
        >
          <!-- email -->
          <b-form-group label="E-mail" label-for="forgot-password-email">
            <b-form-input
              id="forgot-password-email"
              v-model="email"
              :disabled="loading"
              :class="$v.email.$error ? 'is-invalid' : ''"
              name="forgot-password-email"
              placeholder="naam@voorbeeld.be"
            />
          </b-form-group>

          <!-- submit button -->
          <b-button variant="primary" block type="submit" :disabled="loading">
            <b-spinner v-if="loading" small></b-spinner>
            Wachtwoord herstellen
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Terug naar aanmelden
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { functions } from "@/firebase/firebaseConfig";
import { required, email } from "vuelidate/lib/validators";
import LogoText from "@core/layouts/components/LogoText.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    LogoText,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
  },
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    restore() {
      this.error = null;
      if (!this.$v.$invalid) {
        this.loading = true;
        functions
          .httpsCallable("userForgotPassword")({ email: this.email })
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Check je e-mail 📩",
                text:
                  "U ontvangt zodadelijk een e-mail met verdere instructies!",
                icon: "CheckIcon",
                variant: "success",
              },
            });

            this.loading = false;
            this.$router.push({name: 'auth-login'});
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Er ging is fout, probeer het opnieuw.",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$v.$touch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Formulier incorrect",
            text: "Zorg dat alle velden correct ingevuld zijn.",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
